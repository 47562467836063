import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import { AlertContainer } from 'summer';
import Routes from './Routes';

const App: FC = () => {
  return (
    <>
      <ToastContainer position="top-right" newestOnTop={true} />
      <AlertContainer />
      <Routes />
    </>
  );
};

export default App;
