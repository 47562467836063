import React, { FC, useEffect, useState } from 'react';
import { Label, Modal, ModalBody, Progress as ProgressBar } from 'reactstrap';

export interface ProgressProps {
  setNewMessage?: boolean;
  message?: string;
  progressMessage?: string;
  size?: string;
}

const Progress: FC<ProgressProps> = props => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  return (
    <Modal centered={true} isOpen={true} backdrop="static" size={props.size || 'md'}>
      <ModalBody>
        <ProgressBar animated={true} className="progress-bar-rounded progress-animated-alt" color="first" striped={true} value={100}>
          {props.progressMessage || ' '}
        </ProgressBar>
        {message && <Label className="text-center m-0 pt-2">{message}</Label>}
      </ModalBody>
    </Modal>
  );
};

export default Progress;
