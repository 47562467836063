import { faInstagram, faLinkedin, faTelegram, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC } from 'react';

import './Networks.css';

export interface NetworksProps {
  className?: string;
}

const Networks: FC<NetworksProps> = props => {
  return (
    <ul className={cx('networks', props.className)}>
      <li>
        <a href="https://www.youtube.com/@calimaerp" target="_blank" rel="noopener noreferrer" title="Youtube - Calima">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/calimaerp/" target="_blank" rel="noopener noreferrer" title="Instagram - Calima">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a href="https://br.linkedin.com/company/projetus-ti" target="_blank" rel="noopener noreferrer" title="LinkedIn - Projetus TI">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+5532999682691&text=Oi,%20estou%20precisando%20da%20sua%20ajuda"
          target="_blank"
          rel="noopener noreferrer"
          title="WhatsApp - Projetus TI"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </li>
      <li>
        <a href="https://t.me/calimaerpcontabil" target="_blank" rel="noopener noreferrer" title="Telegram - Projetus TI">
          <FontAwesomeIcon icon={faTelegram} />
        </a>
      </li>
    </ul>
  );
};

export default Networks;
