import axios from 'axios';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Button, Col, Form, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  consultarSituacao,
  dec,
  enc,
  environment,
  ERROR,
  INFORMATION,
  InputGroupInline,
  InputType,
  isEmpty,
  loginToken,
  notificarFalhaLogin,
  recuperarLogin,
  showToast,
  SuspenseLoading,
  useLocalStorage,
  validarLoginController,
  WARNING,
  writeInLocalStorage,
} from 'summer';
import calimaNovo from '../../assets/calima_novo.png';
import Networks from '../../components/networks/Networks';
import Progress from '../../components/progress/Progress';
import { TIPO_USUARIO_LOGIN } from '../../models/enumerated/tipoUsuarioLoginEnum';

import './login.css';

interface LoginForm {
  usuario: string;
  usuarioRecuperar?: string;
  senha: string;
}

const initialState: LoginForm = {
  senha: '',
  usuario: '',
};

enum ViewStates {
  LOGIN,
  RECUPERAR,
  TOKEN,
}

declare global {
  interface Window {
    PROJETUS_CRISP: any;
  }
}

const CONTAINER_STATUS_OK = 90;
const CONTAINER_STATUS_STARTING = 10;

const TIMEOUT = 15000;
const DELAY = 3000;
const STATUS_404 = 404;
const SITE_KEY = '6LdB_t8ZAAAAACfmE2oTqf0OnTIIpZZhBSmuv8PI';
const URL_CALIMA_APP = 'https://www.calimaerp.com/app';
const URL_DADOS = 'https://login-api-controller.projetusti.com.br/conteudoTelaLogin/get?codigoSistema=6';
const URL_LOCALHOST = 'http://localhost:8080/calima/';

const LoginForm: FC = () => {
  const formProps = useForm<LoginForm>({ defaultValues: initialState, mode: 'onBlur' });
  const [loginTokenStorage, writeLoginTokenStorage] = useLocalStorage('loginToken');
  const [serverStorage, writeServerStorage, deleteServerStorage] = useLocalStorage('server');
  const [currentState, setCurrentState] = useState(ViewStates.LOGIN);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [exigirCaptchaLogin, setExigirCaptchaLogin] = useState(false);

  const [loginData, setLoginData] = useState({
    imagemFundoLink: null,
    subtitulo: 'Preencha seus dados para entrar',
    subtituloLink: null,
    terceiraLinha: null,
    terceiraLinhaLink: null,
    titulo: 'Bem-vindo!',
    tituloLink: null,
    urlImagemFundo: null,
  });

  const location = useLocation();
  const capctha = useRef(null);

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadData = async () => {
    try {
      const styleData = await axios.get(URL_DADOS, { headers: { 'Content-Type': 'application/json' } }).then(res => res.data);
      if (styleData) {
        setLoginData(styleData);
      }
    } catch (e) {}
    init();
  };

  const resetSession = msg => {
    const msgRedir = msg ? `/?msg=${msg}` : '';
    if (serverStorage) {
      deleteServerStorage();
    }
    window.location.href = environment.production ? `https://calima.app${msgRedir}` : `http://localhost:3000${msgRedir}`;
  };

  const init = () => {
    resetCapctha();

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    const reset = urlParams.get('reset');
    const msg = urlParams.get('msg');
    const tipoUsuarioLogin = TIPO_USUARIO_LOGIN.find(it => it.value === msg);

    if (reset) {
      resetSession(msg);
    } else if (token) {
      efetuarLoginToken(token);
    } else if (tipoUsuarioLogin) {
      showToast(tipoUsuarioLogin.label, tipoUsuarioLogin.extraFields.toastType);
    } else if (serverStorage) {
      const server = JSON.parse(serverStorage);

      const codigoCliente = dec(server.codigoCliente);
      const usuario = dec(server.usuario);

      consultarSituacao({
        codigoCliente,
        dataValidacaoCPF: null,
        errorFunc,
        logar: false,
        resultFunc: (result, tokenAcesso) => {
          writeLoginTokenStorage(tokenAcesso);
          if (result.containerStatus === CONTAINER_STATUS_OK) {
            verificarConexaoCloud(
              dec(server.aplicationReleaseName),
              dec(server.urlSistema),
              codigoCliente,
              usuario,
              dec(server.senha),
              dec(server.acesso)
            );
          } else {
            resetSession(msg);
          }
        },
        tokenAcesso: loginTokenStorage,
        usuario,
        warningFunc,
      });
    }
  };

  const onSubmit = async (data: LoginForm, event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (exigirCaptchaLogin && isEmpty(recaptchaToken)) {
      alertManager.emit({
        message: 'ReCaptcha inválido',
        type: AlertType.INFORMATION,
      });
      setIsSubmitting(false);
      return;
    }
    currentState === ViewStates.RECUPERAR ? dispatchRecuperarLogin(data) : efetuarLogin(data, 'CLIENTE');
  };

  const dispatchRecuperarLogin = (data: LoginForm) => {
    recuperarLogin({
      errorFunc,
      login: data.usuarioRecuperar,
      resultFunc: (result, tokenAcesso) => {
        writeLoginTokenStorage(tokenAcesso);
        writeInLocalStorage('u', { ...data, senha: enc(data.senha) });
        setCurrentState(ViewStates.LOGIN);
        showToast('Sua nova senha foi enviada para o e-mail informado. Por favor verifique sua caixa de entrada.', INFORMATION);
      },
      tokenAcesso: loginTokenStorage,
    });
  };

  const efetuarLoginToken = (tokenController: string) => {
    loginToken({
      errorFunc: mensagem => {
        showToast(mensagem, ERROR);
        setCurrentState(ViewStates.LOGIN);
      },
      resultFunc: (result, tokenAcesso) => {
        writeLoginTokenStorage(tokenAcesso);
        efetuarLogin({ senha: result.senha, usuario: result.usuario }, 'TOKEN');
      },
      token: tokenController,
      tokenAcesso: loginTokenStorage,
    });
  };

  const promisedAlert = (message, type = AlertType.WARNING_YES_NO) =>
    new Promise(onOkClick => {
      alertManager.emit({
        message,
        onOkClick,
        type,
      });
    });

  const efetuarLogin = (data: LoginForm, acesso: string) => {
    setProgress(<Progress progressMessage="Aguarde..." size="sm" />);
    validarLoginController({
      errorFunc,
      login: data.usuario,
      resultFunc: (result, tokenAcesso) => {
        const codigoCliente = String(result.codigoCliente);
        writeLoginTokenStorage(tokenAcesso);
        if (result.calimaCloudHabilitado) {
          if (validarResultConsultaSit(result)) {
            let urlSistema = result.urlSistema;
            if (result.codigoCliente === 0) {
              urlSistema = URL_LOCALHOST;
            }

            const server = JSON.stringify({
              acesso: enc(acesso),
              aplicationReleaseName: enc(result.aplicationReleaseName),
              codigoCliente: enc(codigoCliente),
              senha: enc(data.senha),
              urlSistema: enc(urlSistema),
              usuario: enc(result.usuario),
            });

            writeServerStorage(server);
            verificarConexaoCloud(result.aplicationReleaseName, urlSistema, codigoCliente, result.usuario, data.senha, acesso);
          } else {
            setProgress(null);
            setIsSubmitting(false);
          }
        } else {
          setProgress(null);
          alertManager.emit({
            message:
              'Você não possui nenhum serviço em nuvem habilitado. Entre em contato com nossa central de vendas e adquira um plano para conseguir acessar seu Calima na nuvem.',
            onOkClick: () => {
              // @ts-ignore
              window.$crisp.push(['do', 'chat:open']);
            },
            textWarningNo: 'Cancelar',
            textWarningYes: 'Central de atendimento',
            type: AlertType.WARNING_YES_NO,
          });
          setIsSubmitting(false);
        }
      },
      senha: data.senha,
      tokenAcesso: loginTokenStorage,
      validar: response => {
        const possuiAcesso = response.tiposAcesso.includes(101) || response.administrador;
        if (!possuiAcesso) {
          setProgress(null);
          setIsSubmitting(false);
          alertManager.emit({
            message: 'O acesso ao Calima para o seu usuário não está habilitado.',
            type: AlertType.WARNING,
          });
        }
        return possuiAcesso;
      },
      warningFunc,
    });
  };

  const validarResultConsultaSit = result => {
    let valido = true;
    if (result.containerStatus !== CONTAINER_STATUS_OK && result.containerStatus !== CONTAINER_STATUS_STARTING) {
      valido = false;
      alertManager.emit({
        message: result.containerStatusMessage,
        type: AlertType.INFORMATION,
      });
    } else if (result.aplicationReleaseName && !result.aplicationReleaseName.includes('react')) {
      valido = false;
      const msg = (
        <>
          <p>
            Você não foi migrado para a nova versão do Calima 5.0 e por isso não é possível acessar pelo endereço
            <b> https://calima.app</b>.
          </p>
          <br />
          <p>Caso deseje utilizar a nova versão entre em contato com o setor de migração.</p> <br />
          <p>Deseja baixar o Calima App para acessar o Calima na versão que esta liberada para você?</p>
        </>
      );
      promisedAlert(msg).then(openSiteCalimaApp);
    }
    return valido;
  };

  const verificarConexaoCloud = async (
    aplicationReleaseName: string,
    urlSistema: string,
    codigoCliente: string,
    usuario: string,
    senha: string,
    acesso: string
  ) => {
    const baseUrl = urlSistema + 'spring/ger/obterVersaoCalima';
    const ok = await verificarConexao(baseUrl);
    if (ok) {
      const ehLocal = urlSistema === URL_LOCALHOST;
      if (ehLocal) {
        const result = await axios.get(URL_LOCALHOST + 'build.json').then(res => res.data);
        aplicationReleaseName = result.build;
      }
      connectarBackEnd(aplicationReleaseName, urlSistema, codigoCliente, usuario, senha, ehLocal, acesso);
    } else {
      setProgress(null);
      alertManager.emit({
        message:
          'Não foi possível conectar ao seu Servidor WEB. Feche o sistema, aguarde alguns minutos e entre novamente. ' +
          'Caso o problema persista, entre em contato com nossa equipe de suporte.',
        type: AlertType.INFORMATION,
      });
      setIsSubmitting(false);
      notificarFalhaLogin({
        codigoCliente,
        errorFunc: null,
        resultFunc: null,
        tokenAcesso: loginTokenStorage,
      });
    }
  };

  const verificarConexao = async (url: string, maximumRetry = 15, attempt = 1, delay = 0) => {
    try {
      if (attempt >= maximumRetry) {
        setProgress(null);
        return false;
      } else {
        setProgress(<Progress message={messages()} />);
        await new Promise(r => setTimeout(r, delay));
        const response = await axios.get(url, { timeout: TIMEOUT });
        if (response && response.status === STATUS_404) {
          return verificarConexao(url, maximumRetry, attempt + 1, DELAY);
        } else {
          setProgress(null);
          return true;
        }
      }
    } catch (ignored) {
      return verificarConexao(url, maximumRetry, attempt + 1, DELAY);
    }
  };

  const connectarBackEnd = (
    aplicationReleaseName: string,
    urlSistema: string,
    codigoCliente: string,
    usuario: string,
    senha: string,
    ehLocal: boolean,
    acesso: string
  ) => {
    if (window.PROJETUS_CRISP) {
      window.PROJETUS_CRISP.hide();
    }

    const aplicationRelease = aplicationReleaseName ? aplicationReleaseName.split('-')[1] : 'testing';
    const params = new URLSearchParams();

    params.set('p1', urlSistema);
    params.set('p2', enc(codigoCliente));
    params.set('p3', enc(usuario));
    params.set('p4', enc(senha));
    params.set('a', enc(acesso));

    const urlFront = ehLocal ? 'http://localhost:3000' : `https://${aplicationRelease}.calima.app`;

    window.location.href = `${urlFront}/?${params.toString()}`;
  };

  const messages = () => {
    const msgs = [
      'Aguarde! Nosso robô está criando seu ambiente de acesso ao Calima.',
      'Aguarde! Estamos quase concluindo... espere mais um pouco.',
      'Aguarde! Já está quase tudo pronto para seu acesso ao Calima.',
      'Aguarde! Nosso robô está configurando os processos finais de acesso.',
    ];
    return msgs[Math.floor(Math.random() * msgs.length)];
  };

  const openSiteCalimaApp = () => {
    window.open(URL_CALIMA_APP, '_blank');
  };

  const errorFunc = mensagem => {
    setProgress(null);
    showToast(mensagem, ERROR);
    setIsSubmitting(false);
    resetCapctha();
    setExigirCaptchaLogin(true);
  };

  const warningFunc = (mensagem, urlAcesso) => {
    const onClickHandler = () => window.open(urlAcesso, '_blank');
    showToast(urlAcesso ? <div onClick={onClickHandler}>{mensagem}</div> : mensagem, { ...WARNING, delay: false });
    setIsSubmitting(false);
    setProgress(null);
  };

  const recuperarClickHandler = () => {
    resetCapctha();
    setCurrentState(ViewStates.RECUPERAR);
    formProps.triggerValidation();
  };

  const voltarLogin = () => {
    resetCapctha();
    setCurrentState(ViewStates.LOGIN);
    formProps.triggerValidation();
  };

  const resetCapctha = () => {
    if (capctha && capctha.current) {
      capctha.current.reset();
      setRecaptchaToken('');
    }
  };

  const handleChange = value => {
    setRecaptchaToken(value);
  };

  const backButton = (
    <Button size="sm" color="link" block={true} onClick={voltarLogin}>
      Voltar
    </Button>
  );

  const reCapctha =
    exigirCaptchaLogin === true ? (
      <div className="text-center">
        <ReCAPTCHA style={{ display: 'inline-block' }} ref={capctha} render="explicit" sitekey={SITE_KEY} onChange={handleChange} />
      </div>
    ) : null;

  const loginForm = currentState === ViewStates.LOGIN && (
    <>
      {progress}
      <InputGroupInline
        formProps={{ ...formProps, validation: { required: true } }}
        id="usuario"
        label="Usuário, CPF, e-mail, código de cliente ou CNPJ"
      />
      <InputGroupInline formProps={{ ...formProps, validation: { required: true } }} id="senha" label="Senha" type={InputType.PASSWORD} />
      {reCapctha}
      <div>
        <Row>
          <Col className="pr-0">
            <Button size="sm" block={true} color="first" disabled={isSubmitting} className="mb-2">
              Entrar
            </Button>
          </Col>
          <Col className="pl-0">
            <Button size="sm" block={true} color="link" className="px-0" onClick={recuperarClickHandler}>
              Recuperar senha
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const recuperarForm = currentState === ViewStates.RECUPERAR && (
    <>
      <InputGroupInline formProps={{ ...formProps, validation: { required: true } }} id="usuarioRecuperar" label="Informe seu usuário ou e-mail" />
      {reCapctha}
      <Button size="sm" block={true} color="first">
        Recuperar
      </Button>
      {backButton}
    </>
  );

  const getTitulo = () =>
    !!loginData.tituloLink ? (
      <a href={loginData.tituloLink} target="_blank" rel="noopener noreferrer">
        {loginData.titulo}
      </a>
    ) : (
      loginData.titulo
    );
  const getSubTitulo = () =>
    !!loginData.subtituloLink ? (
      <a href={loginData.subtituloLink} target="_blank" rel="noopener noreferrer">
        {loginData.subtitulo}
      </a>
    ) : (
      loginData.subtitulo
    );
  const getTerceiraLinha = () =>
    !!loginData.terceiraLinhaLink ? (
      <a href={loginData.terceiraLinhaLink} target="_blank" rel="noopener noreferrer">
        {loginData.terceiraLinha}
      </a>
    ) : (
      loginData.terceiraLinha
    );
  const getBackgroundStyle = loginData => {
    if (loginData.urlImagemFundo) {
      return {
        backgroundImage: `url('${loginData.urlImagemFundo}')`,
      };
    } else {
      return {
        backgroundColor: 'white',
      };
    }
  };

  return !!loginData ? (
    <div className="login-wrapper">
      <div className="login-container min-vh-100 d-flex justify-content-center">
        {currentState !== ViewStates.TOKEN && (
          <>
            <Row>
              <Col>
                <div className="text-center">
                  <a href="https://www.calimaerp.com/" target="_blank" rel="noopener noreferrer" className="mb-2">
                    <img style={{ width: 300 }} src={calimaNovo} alt="Calima ERP" />
                  </a>
                  <div className="titles">
                    {!!loginData.titulo && <h2>{getTitulo()}</h2>}
                    {!!loginData.subtitulo && <h3>{getSubTitulo()}</h3>}
                    {!!loginData.terceiraLinha && <h3>{getTerceiraLinha()}</h3>}
                  </div>
                </div>
                <Form noValidate={true} onSubmit={formProps.handleSubmit(onSubmit)}>
                  {loginForm}
                  {recuperarForm}
                </Form>
                <div className="text-center pt-1 text-black">
                  Não possui login?
                  <strong style={{ marginLeft: 10 }}>
                    <a href="https://centraldocliente.projetusti.com.br/#/cadastro" target="_blank" rel="noopener noreferrer">
                      Cadastre-se agora!
                    </a>
                  </strong>
                </div>
              </Col>
            </Row>
            <Networks className="networks-login" />
            <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer" className="copyright">
              &copy; ProjetusTI
            </a>
          </>
        )}
      </div>
      <div className="image-container" style={getBackgroundStyle(loginData)}>
        {!!loginData.imagemFundoLink && (
          <a href={loginData.imagemFundoLink} rel="noopener noreferrer" target="_blank">
            &nbsp;
          </a>
        )}
      </div>
    </div>
  ) : (
    <SuspenseLoading />
  );
};

export default LoginForm;
