import { ERROR, WARNING } from 'summer';

export const CLIENTE = { value: 'CLIENTE', label: 'Usuários do tipo CLIENTE não podem acessar este sistema.', extraFields: { toastType: ERROR } };
export const LOGIN = {
  extraFields: { toastType: WARNING },
  label: 'Sua sessão foi encerrada devido a um novo login em outra estação.',
  value: 'LOGIN',
};

export const TIPO_USUARIO_LOGIN = [CLIENTE, LOGIN];
